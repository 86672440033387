<template>
  <div class="introducePage">
    <div class="section-aside">
      <p>宁波海曙区望春街道</p>
      <p>五江口现代社区</p>
    </div>
    <div class="section-main">
      <div class="main-content">
        <p>
          五江口社区因五港口河、太平桥河、洞船河、严家河与姚江贯通，五江汇聚，而成为海曙区姚江沿岸的重要区块。社区地处望春街道中西部，面积0.8平方公里，成立于2015年5月，以五江湾小区为主体，居民主要来自海曙其他7个城区街道的拆迁区块，共4469户、6276人，是典型的拆迁安置复合型社区。其中老年人等弱势群体1500余人，青少年和幼儿2000多人，占比达到67.8%，“一老一小”服务需求突出。根据社区实际情况，社区党委一直坚持“五最党建”理念，从最细微处、最困难处、最薄弱处、最复杂处、最前沿处抓党建，探索出一条“五最五美”党建统领社会组织建设，助力社区打造幸福吾家的实践路径。五江口社区特色鲜明，未来社区创建之初，思路清晰，紧扣居民需求，落实空间改造、运营落地。让居民能够在创建的过程中有感知、有获得，打造一个有温度的未来社区。创建过程中结合社区特色在地文化“五美五江、吾爱吾家”，打造全新九大场景。
        </p>
        <h3>一、聚焦“一老一小”群体，打造老幼友好型社区</h3>
        <p>
          在党群服务中心内设置居家养老服务站，组织开展为“老”服务场景，共有7个“YUE”主题会馆，我们引进广安养怡院专业社会组织进行运营，还联动北斗心灵等15个社区社会组织，为老人提供一站式养老服务。
        </p>
        <p>
          建设运营共享心理咨询空间。针对老年人心理问题进行疏导。每周三由心援协会提供专业服务，平时由社区“吾家谈”社会组织为有需要的居民提供日常服务。
        </p>
        <p>
          打造每月固定服务日，针对居民开展相关活动，505是指每个月5日，开展缝纫修补服务；521是指每个月21日，社区会举行便民服务活动；525是指每个月25日，会提供理发服务等等一系列的未老服务。本次未来社区打造在文体中心新增社区食堂，目前已有30余位居民完成订餐，切实解决了“一老”群体用餐的需求。
        </p>
        <p>
          “一小”上联动辖区内社会组织，在吾家文体中心打造儿童驿站、社区书房、四点半学堂，同时联动专业第三方机构开展少儿体适能、运动社群等一系列活动，打造五江口社区少儿成长体系—“知行和艺”（知识类、行动类、公益类、艺术类四大类成长活动）全方位关注社区“一小”群体的成长。
        </p>
        <h3>二、辖区社会组织聚力，共同打造特色治理模式</h3>
        <p>
          社区共有在册党员183人，在职党员47人，流动党员63人，从社区第一个社会组织“一米阳光”送餐队成立，发展到目前已有社会组织45个。其中25个社会组织建立了功能性党支部，另外20个派驻了党建指导员。我们从最细微处引领，围绕居民需求，培育“能人型”党支部书记、社会组织领头人，通过书记领衔项目、红旗支部成长营，加速社会组织孵化；从最困难处帮扶，引进了北斗心灵、心援协会等社会组织，帮助老人和残障群体开展
          “六助”服务；从最薄弱处聚力，通过组建业缘趣缘性社会组织，让7个拆迁地块的“小家人”变成现在的“大家人”；在最复杂处和最前沿处，我们依托专业社会组织来进行破题和探索，比如2021年同悦社工组织帮助我们解决小区下水道污水堵塞，“纸飞机”帮我们解决家门口的托育难题。借助未来社区的创建，多元化的治理力量的参与，治理场景的革新和探索让社区的治理模式有了很大的提升，目前也在不断向社区自治的模式培育。
        </p>
        <h3>三、多元化运营可持续探索</h3>
        <p>
          开展建设模式创新，将运营思维整体贯穿到规划、设计、建设全生命周期，明确投建管运相关主体并说明具体举措。合理配比不同的主体，结合公益性，普惠性和经营性三种业态，使社区各场景得到可持续运营，解决邻里互动的活动场所，实现就近活动、就近创业和社区内提供建立社区服务机构的问题。例如社区运动空间，运营提前介入，采用市场化和社区普惠共存的模式，既满足居民的健身需求，又实现商业运营的良性运作。
        </p>
        <p>
          引入第三方孵化机构和组织，挖掘社区达人、社群社团组织，引导社区共创者们打造“多元参与、多元服务、多元宣传”的“社区营造”模式。目前社区达人张老师已创办了绘本课堂，每周六为社区小朋友讲述绘本故事；程巢宣老师每月定期开展健康讲座；在地组织引力联盒成功举办了社区羽毛球友谊赛、羽毛球公益课等五江口特色品牌，让居民们发挥自己的优势，实现社区运营共享、共治、降本、增效。
        </p>
        <p>
          通过提升改造社区闲置公共空间，引聚客流形成具备商业活力的氛围，进而为社区运营提供造血功能。如此次改造的羽毛球场馆，就是在基于居民需求的前提下，招引专业服务商，用租金收入“反哺”社区；其次，建立“吾家积分”运营机制，面向居民开放代币商城、志愿者商城两大线上应用，以服务换取代币、用代币兑换第三方产品，引导居民共同参与社区治理与志愿服务，实现社区运营的共享共建、降本增效，尝试实现社区的可持续性运营。截至目前五江口未来社区已向居民提供120次场馆预约，1400余人次活动报名，4802次积分兑换服务，根据居民需求不断地进行迭代升级。
        </p>
        <p>
          五江口未来社区创建，始终围绕一统三化九场景进行打造，力求实现四大目标：空间前后有变化、治理模式有创新、居民群众有获得、运营持续有未来。目前社区在居民获得感及运营可持续探索上已有一定成果，后续将持续落实公益+普惠的服务模式，探索未来社区特色模式。
        </p>
      </div>

      <div class="main-picture">
        <img src="./img/introducePageEight_show_one.jpeg" al t="" />
      </div>

      <div class="main-picture">
        <img src="./img/introducePageEight_show_two.png" alt="" />
      </div>

      <div class="main-picture">
        <img src="./img/introducePageEight_show_three.png" alt="" />
      </div>

      <div class="main-picture">
        <img src="./img/introducePageEight_show_four.png" alt="" />
      </div>

      <div class="main-picture">
        <img src="./img/introducePageEight_show_five.jpeg" alt="" />
      </div>

      <div class="main-video">
        <video
          controls
          id="video"
          autoplay="autoplay"
          src="https://wjk.zhuneng.cn/imgForMini/static/index/introduce.m4v"
        ></video>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      timer: null,
    };
  },
  created() {
    this.init();
  },
  methods: {
    init() {
      const that = this;
      //--创建页面监听，等待微信端页面加载完毕 触发音频播放
      document.addEventListener("WeixinJSBridgeReady", function () {
        that.$nextTick(() => {
          document.getElementById("video").play();
        });
      });
    },
  },
};
</script>

<style lang="less" scoped>
.introducePage {
  position: relative;
  box-sizing: border-box;
  min-height: 100vh;
  padding: 378px 0 80px;
  background: url(./img/introducePage_bg.png) no-repeat top left;
  background-size: 100% auto;
  background-color: #dfeeff;
  .section-aside {
    position: absolute;
    // top: 160px;
    // left: 36px;
    top: 120px;
    left: 20px;
    width: 100%;
    // text-align: center;
    font-size: 64px;
    color: #ffffff;
    line-height: 84px;
    text-shadow: 5px 2px #4a7196;
    p:last-child {
      padding-top: 20px;
      padding-left: 40px;
    }
  }
  .section-main {
    box-sizing: border-box;
    padding: 38px 30px 0px;
    width: 100%;
    background: url(./img/introducePage_across.png) no-repeat top center;
    background-size: calc(100% - 20px) 80px;
    .main-content {
      // width: 100%;
      padding: 50px 30px 56px;
      background-color: #fff;
      border-radius: 0 0 6px 6px;
      color: rgba(0, 0, 0, 0.85);
      // text-align-last: justify;
      text-align: justify;
      h3 {
        font-weight: bold;
        padding: 12px 0px;
        font-size: 28px;
        line-height: 48px;
      }
      p {
        font-size: 28px;
        line-height: 40px;
        text-indent: 2em;
      }
    }
    .main-video {
      position: relative;
      margin-top: 30px;
      background-color: #fff;
      border-radius: 0 0 6px 6px;
      padding: 68px 22px 40px;
      text-align: center;
      height: 400px;
      ::v-deep video {
        width: 100%;
        height: 100%;
        object-fit: fill;
        background: #fff;
      }
    }
    .main-picture {
      position: relative;
      margin-top: 30px;
      background-color: #fff;
      border-radius: 0 0 6px 6px;
      padding: 68px 22px 64px;
      img {
        width: 100%;
        // width: 646px;
        // height: 486px;
      }
      &:not(:last-child)::after {
        content: "";
        display: block;
        position: absolute;
        bottom: -68px;
        left: 28px;
        width: 634px;
        height: 106px;
        background: url(./img/introducePage_vertical.png) no-repeat top center;
        background-size: 100% 100%;
        z-index: 1;
      }
    }
  }
}
</style>
